import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { createOffer } from "../redux/actions/offerActions";
import { OFFER_CREATE_RESET } from "../redux/constants/offerConstants";

import "./FourthStepScreen.css";
import OfferSteps from "../components/OfferSteps";
import pictures from "../assets/image2.png";
import axios from "axios";

const FourthStepScreen = () => {
  const offerCreate = useSelector((state) => state.offerCreate);
  const { success, error, loading, titleAndCategory, cityAndDescription } =
    offerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [stagedFormData, setStagedFormData] = useState(null);
  const [imageCountError, setImageCountError] = useState("");

  const backToStepThree = () => {
    if (userInfo) {
      navigate("/makeoffer/step2");
    } else {
      navigate("/makeoffer/step3");
    }
  };

  const titleAndCategoryStorage = localStorage.getItem("titleAndCategory");
  const titleAndCategoryParsed = JSON.parse(titleAndCategoryStorage);

  const cityAndDescriptionStorage = localStorage.getItem("cityAndDescription");
  const cityAndDescriptionParsed = JSON.parse(cityAndDescriptionStorage);

  let type = titleAndCategoryParsed?.type;
  const clearImagePreview = () => {
    setImagePreview(null);
    setImage("");
  };

  const handleClick = async () => {
    if (image === "") {
      setImageCountError("Vous devez ajouter une image.");
      return;
    } else {
      const formData = new FormData();

      formData.append("image", stagedFormData);
      setUploading(true);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post("/api/upload", formData, config);
        setImage(data?.path);
        setUploading(false);
        dispatch(
          createOffer({
            user: userInfo._id,
            title: titleAndCategoryParsed.offerName,
            category: titleAndCategoryParsed.category,
            subcategory: titleAndCategoryParsed.subCategory,
            city: cityAndDescriptionParsed.city,
            type,
            price: cityAndDescriptionParsed.price,
            image,
            description: cityAndDescriptionParsed.description,
          })
        );
      } catch (error) {
        console.error(error);
        setUploading(false);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setStagedFormData(file);
    setImage(file ? file?.name : "");
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  useEffect(() => {
    document.title = "Création d'annonce";
    if (success) {
      navigate("/offersuccess");
      dispatch({
        type: OFFER_CREATE_RESET,
      });
    }
  }, [navigate, success, dispatch]);

  return (
    <div className="offersummaryscreen">
      <div className="summary__inside">
        <OfferSteps step1 step2 step3 step4 />
        <h1>Vue d'ensemble de l'annonce</h1>

        <div className="offer__summaryBox">
          {titleAndCategoryParsed && cityAndDescriptionParsed && (
            <>
              <div className="info__miniSection">
                <h2>Titre de l'offre</h2>
                <p>{titleAndCategoryParsed.offerName}</p>
              </div>
              <div className="info__miniSection">
                <h2>Type</h2>
                <p>{type === "rent" ? "Location" : "Vente"}</p>
              </div>
              <div className="info__miniSection">
                <h2>Prix</h2>
                <p>
                  {cityAndDescriptionParsed.price}
                  {type === "rent" ? "DH / jour" : " DH"}
                </p>
              </div>
              <div className="info__miniSection">
                <h2>Catégory</h2>
                <p>{titleAndCategoryParsed.category}</p>
              </div>
              <div className="info__miniSection">
                <h2>Sous-catégory</h2>
                <p>{titleAndCategoryParsed.subCategory}</p>
              </div>
              <div className="info__miniSection">
                <h2>Ville</h2>
                <p>{cityAndDescriptionParsed.city}</p>
              </div>
              <div className="info__miniSection">
                <h2>Déscription</h2>
                <p>{cityAndDescriptionParsed.description}</p>
              </div>
            </>
          )}
          <div className="info__miniSection">
            <h2>Image</h2>
            <div className="offer__images">
              <div className="image__uploadSummary">
                <div className="inside__upload">
                  <label htmlFor="upload-input">
                    <img src={pictures} draggable="false" alt="pictures" />
                  </label>
                  <input
                    id="upload-input"
                    type="file"
                    onChange={handleImageChange}
                    multiple
                    accept="image/*"
                  />
                </div>
                {imagePreview && (
                  <div className="image-preview-container">
                    <div className="image-previewsummary">
                      <img
                        src={imagePreview}
                        alt={`Image alt`}
                        className="image__previews"
                      />
                      <i
                        className="fa-solid fa-trash close-iconoffersumm"
                        onClick={() => clearImagePreview()}
                      ></i>
                    </div>
                    {/* ))} */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {error && <h4 className="offer__error">{error}</h4>}
        {imageCountError && <h4 className="offer__error">{imageCountError}</h4>}
        <section className="offer__buttons">
          <button className="save__offer" onClick={backToStepThree}>
            Retour
          </button>
          <button className="save__offer" onClick={handleClick}>
            Suivant
          </button>
        </section>
      </div>
    </div>
  );
};

export default FourthStepScreen;
