import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import OfferRow from "../components/OfferRow";
import "./ProfileScreen.css";
import userPic from "../assets/profilePic.png";
import {
  getUserDetails,
  updateUserProfile,
} from "../redux/actions/userActions";
import Footer from "../components/Footer";
import { deleteMyOffer, listMyOffers } from "../redux/actions/offerActions";

const ProfileScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    userInfo: userUpdated,
    success,
    error,
    loading: updatedLoading,
  } = userUpdateProfile;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const {
    user,
    error: userDetailsError,
    loading: userDetailsLoading,
  } = userDetails;

  const myOffersList = useSelector((state) => state.myOffersList);
  const { myOffers, loading: myOffersLoading } = myOffersList;

  const offerDelete = useSelector((state) => state.offerDelete);
  const { success: successDelete } = offerDelete;

  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Vous allez supprimer cet offre. Etes vous sur ?")) {
      dispatch(deleteMyOffer(id));
    }
  };

  useEffect(() => {
    document.title = "Paramètres | L'matarian";
    if (userInfo) {
      dispatch(getUserDetails(userInfo._id));
      dispatch(listMyOffers(userInfo._id));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, successDelete]);

  const handleModification = (e) => {
    e.preventDefault();
    dispatch(
      updateUserProfile({
        fullName: name,
        username,
        email,
        phoneNumber,
        password,
      })
    );
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="profilescreen"
    >
      <div className="profilescreen__insider">
        <h2>Toutes mes info</h2>
        <form className="profile__elements">
          <div className="info__side">
            <h2>Les informations personnelles</h2>
            {user && (
              <>
                <div className="profile__details">
                  <img src={userPic} alt="profile pic" />
                  <p>{user.userName}</p>
                </div>
                <>
                  <div className="username__section_register">
                    <label>Nom complet</label>
                    <input
                      type="text"
                      placeholder={user.fullName}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="username__section_register">
                    <label>Nom d'utlisateur</label>
                    <input
                      type="text"
                      placeholder={user.userName}
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>

                  <div className="username__section_register">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder={user.email}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="username__section_register">
                    <label>Numéro du téléphone</label>
                    <input
                      type="text"
                      maxLength="10"
                      placeholder={"0" + user.phoneNumber}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="username__section_register">
                    <label>Mot de passe</label>
                    <input
                      type="password"
                      placeholder="Entrer votre mot de passe"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <button type="submit" onClick={handleModification}>
                    Modifier les infos personnelles
                  </button>
                  {success && (
                    <p className="updatedInfo__msg">
                      Your personal info are being updated
                    </p>
                  )}
                </>
              </>
            )}
          </div>

          <div className="offers__side">
            <h2>Mes Annonces</h2>
            {userInfo && (
              <table>
                <thead>
                  <tr>
                    <th>Titre</th>
                    <th>Ville</th>
                    <th>Crée en</th>
                    <th>Modifier</th>
                    <th>Supprimer</th>
                  </tr>
                </thead>
                <tbody>
                  {myOffers &&
                    myOffers.map((offer) => (
                      <OfferRow
                        key={offer._id}
                        click={handleModification}
                        clickDelete={deleteHandler}
                        identify={offer._id}
                        title={offer.title}
                        createdAt={offer.createdAt}
                        city={offer.city}
                      />
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </form>
      </div>
      <Footer />
    </motion.div>
  );
};

export default ProfileScreen;
