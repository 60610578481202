import { useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Backdrop from "./components/Backdrop";
import NavBar from "./components/NavBar";
import SideDrawer from "./components/SideDrawer";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import MakeOfferScreen from "./screens/MakeOfferScreen";
import OfferScreen from "./screens/OfferScreen";
import OfferSuccessScreen from "./screens/OfferSuccessScreen";
import ProfileScreen from "./screens/ProfileScreen";
import RegisterScreen from "./screens/RegisterScreen";
import SearchScreen from "./screens/SearchScreen";
import UpdateMyOffer from "./screens/UpdateMyOffer";
import OfferSummaryScreen from "./screens/OfferSummaryScreen";
import MakeRequestScreen from "./screens/MakeRequestScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import SendInstructionsScreen from "./screens/SendInstructionsScreen";

//Steps
import SecondStepScreen from "./screens/SecondStepScreen";
import FirstStepScreen from "./screens/FirstStepScreen";
import ThirdStepScreen from "./screens/ThirdStepScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import EmailSentResetScreen from "./screens/EmailSentResetScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import AdminDashScreen from "./screens/AdminDashScreen";
import FourthStepScreen from "./screens/FourthStepScreen";

function App() {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <Router>
      <div className="App">
        <NavBar click={() => setSideToggle(true)} />
        <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
        <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
        {/* All Routes */}
        <Routes>
          <Route path="/" exact element={<HomeScreen />} />
          <Route
            path="/discover/page/:pageNumber"
            exact
            element={<SearchScreen />}
          />

          <Route path="/offer/:id" exact element={<OfferScreen />} />
          <Route path="/myoffer/update/:id" exact element={<UpdateMyOffer />} />
          <Route path="/newoffer" exact element={<MakeOfferScreen />} />
          <Route path="/newrequest" exact element={<MakeRequestScreen />} />
          {/* <Route path="/offersummary" exact element={<OfferSummaryScreen />} /> */}
          <Route path="/offersuccess" exact element={<OfferSuccessScreen />} />
          <Route path="/login" exact element={<LoginScreen />} />
          <Route path="/register" exact element={<RegisterScreen />} />
          <Route path="/profile" exact element={<ProfileScreen />} />
          <Route path="/makeoffer/step1" exact element={<FirstStepScreen />} />
          <Route path="/makeoffer/step2" exact element={<SecondStepScreen />} />
          <Route path="/makeoffer/step3" exact element={<ThirdStepScreen />} />
          <Route path="/offersummary" exact element={<FourthStepScreen />} />
          <Route path="/about" exact element={<AboutUsScreen />} />
          <Route
            path="/passinstruct"
            exact
            element={<SendInstructionsScreen />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordScreen />}
          />
          <Route
            path="/instructsent"
            exact
            element={<EmailSentResetScreen />}
          />
          <Route path="/dash/global" exact element={<AdminDashScreen />} />
          <Route path="/contactus" exact element={<ContactUsScreen />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
