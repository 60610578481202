import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { getOfferDetails, UpdateOffer } from "../redux/actions/offerActions";
import "./AboutUsScreen.css";

const AboutUsScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const offerDetails = useSelector((state) => state.offerDetails);
  const { offer } = offerDetails;

  useEffect(() => {
    document.title = "A propos de nous | L'matarian";
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.2 }}
      className="aboutusscreen"
    >
      <div className="aboutusscreen__inside">
        <h1>A propos de nous</h1>
        <p>
          Bienvenue chez L'matarian ! Chez L'matarian, nous sommes dévoués à
          fournir des solutions de premier ordre dans l'industrie des
          excavatrices. Notre parcours a commencé il y a [X années/mois] avec un
          objectif simple mais ambitieux : révolutionner la manière dont les
          gens louent et achètent des excavatrices. Au fil des années, notre
          engagement envers l'excellence, associé à notre passion pour servir
          nos clients, a fait de nous un nom de confiance dans le domaine. Notre
          Mission Nous nous efforçons d'offrir des services inégalés,
          garantissant que nos clients trouvent les solutions d'excavatrices
          parfaites pour répondre à leurs besoins. Notre mission est de
          simplifier le processus de location ou d'achat d'excavatrices, le
          rendant sans tracas, fiable et rentable. Ce Qui Nous Démarque
          Équipement de Qualité : Nous sommes fiers de proposer une gamme
          diversifiée d'excavatrices de haute qualité à la location ou à
          l'achat. Chaque machine est méticuleusement entretenue et inspectée
          pour garantir des performances optimales. Approche Axée sur le Client
          : Votre satisfaction est notre priorité. Notre équipe est dédiée à
          comprendre vos besoins et à fournir des solutions sur mesure qui
          dépassent vos attentes. Expertise et Expérience : Forts de plusieurs
          années d'expérience dans le secteur, notre équipe est composée de
          professionnels chevronnés apportant des connaissances et une expertise
          inégalées. Notre Engagement envers Vous Chez [Nom de votre
          entreprise], la transparence, la confiance et la fiabilité sont les
          piliers de notre activité. Nous croyons en une communication ouverte,
          des relations équitables et en la construction de relations durables
          avec nos clients. Que vous souhaitiez louer ou acheter une
          excavatrice, nous sommes là pour vous guider à chaque étape.
          Contactez-nous ! Nous sommes ravis de partir dans cette aventure avec
          vous. Si vous avez des questions ou avez besoin d'aide, n'hésitez pas
          à nous contacter. Adressez-vous à notre équipe au [Détails de Contact]
          ou rendez-vous dans nos bureaux [Adresse Physique]. Travaillons
          ensemble pour répondre à vos besoins en matière d'excavatrices !
        </p>
      </div>
      <Footer />
    </motion.div>
  );
};

export default AboutUsScreen;
