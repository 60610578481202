import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./SendInstructionsScreen.css";
import axios from "axios";

const SendInstructionsScreen = () => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  //State
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  const isEmailValid = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setErrorMsg("");
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const isValid = isEmailValid(inputEmail);
    setValidEmail(isValid);

    if (!validEmail && email != "") {
      setErrorMsg("Format non valide");
    } else {
      setErrorMsg("");
    }
  };

  const handleEmailSending = async (e) => {
    if (validEmail && email !== "") {
      const response = await axios.post("/api/users/resetpassword", {
        email,
      });
      console.log(response.data.code);
      if (response.data.code === 404) {
        setErrorMsg(response.data.message);
      }
      if (response.data.code === 200) {
        navigate("/instructsent");
      }
    }
  };

  useEffect(() => {
    document.title = "réinitialiser le mot de passe | L'matarian";
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  return (
    <div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="makenewoffer__wrapper"
    >
      <div className="makenewoffer">
        <h1>Réinitialiser le mot de passe</h1>
        <div className="newoffer__details">
          <div className="newoffer__name">
            <label>Email correspondant à votre compte</label>
            <input
              className=""
              type="text"
              value={email}
              placeholder="Entrer votre email"
              onChange={(e) => handleEmailChange(e)}
            />
            {errorMsg && <p className="error__msg">{errorMsg}</p>}
          </div>
        </div>
        <button className="send__instruct" onClick={handleEmailSending}>
          Envoyer les instructions
        </button>
      </div>
    </div>
  );
};

export default SendInstructionsScreen;
