import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        <b> &copy;2023 L'matarian</b>
      </p>
      <p>Tous les droits sont réservés.</p>
      <div className="footer__inside">
        <span></span>
        <div>
          <span>
            <p>Besoin d'aide ?</p>
          </span>
          <span>
            <Link to="/contactus">Contactez-nous.</Link>
          </span>
          <span>
            <Link to="/about">A propos.</Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
