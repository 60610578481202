import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./SearchScreen.css";

// Components
import SearchItem from "../components/SearchItem";
import { listOffersWithFilters } from "../redux/actions/offerActions";
import AllCities from "../components/AllCities";
import AllCategories from "../components/AllCategories";
import Paginate from "../components/Paginate";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import BaseVie from "../components/subcatgories/BaseVie";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import Footer from "../components/Footer";

const SearchScreen = () => {
  const [city, setCity] = useState("");

  const [subCategory, setSubCategory] = useState("");
  const [offerType, setOfferType] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const { pageNumber } = useParams() || 1;
  const { state } = useLocation();
  const { keyword } = state || {};
  const [category, setCategory] = useState(
    keyword !== undefined ? keyword : ""
  );
  const offersList = useSelector((state) => state.offersList);
  const { error, offers, page, pages, loading: offersLoading } = offersList;

  const handleMinSliderChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxSliderChange = (event) => {
    setMaxPrice(event.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    let searchParameter = {
      pageNumber,
      city,
      category,
      subCategory,
      offerType,
      minPrice,
      maxPrice,
      searchTerm,
    };

    dispatch(listOffersWithFilters(searchParameter));
  };

  useEffect(() => {
    document.title = "Chercher une offre | L'matarian";

    if (
      pageNumber == 1 &&
      city == "" &&
      category == "" &&
      subCategory == "" &&
      offerType == "" &&
      minPrice == 0 &&
      maxPrice == 1000000 &&
      searchTerm == ""
    ) {
      dispatch(
        listOffersWithFilters({
          pageNumber,
          city: "",
          category: "",
          subCategory: "",
          offerType: "",
          minPrice: 0,
          maxPrice: 1000000,
          searchTerm: "",
        })
      );
    } else {
      dispatch(
        listOffersWithFilters({
          pageNumber,
          city,
          category,
          subCategory,
          offerType,
          minPrice,
          maxPrice,
          searchTerm,
        })
      );
    }
  }, [dispatch, pageNumber]);

  return (
    <div className="searchscreen">
      <div className="inside__searchscreen">
        <div className="filter__area">
          <div className="search__area">
            <h1>Filtres de recherche</h1>

            <AllCities city={city} setCity={setCity} />

            <AllCategories category={category} setCategory={setCategory} />
            <div className="newoffer__name">
              {category === "Travaux en hauteur" ? (
                <>
                  <TravauxEnHateur
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Levage et manutention" ? (
                <>
                  <LevageEtManutention
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Terrassement et excavation" ? (
                <>
                  <TerrassementEtExcavation
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Transport et logistique" ? (
                <>
                  <TransportEtLogistique
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Gros oeuvre et route" ? (
                <>
                  <GrosOeuvre
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "énergie et pompage" ? (
                <>
                  <EnergieEtPompage
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Equipement de chantier" ? (
                <>
                  <EquipementDeChantier
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : category === "Base vie et installation de chantier" ? (
                <>
                  <BaseVie
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <select
              value={offerType}
              className="allcities"
              placeholder="Choisissez le type d'annonce"
              onChange={(e) => setOfferType(e.target.value)}
            >
              <option value={""}>Choisissez le type d'annonce</option>
              <option value={"rent"}>Location</option>
              <option value={"buy"}>Vente</option>
            </select>
            <div className="search__price">
              <section>
                <span>
                  <label htmlFor="minPrice">
                    <p>Min</p>
                  </label>
                </span>
                <input
                  type="number"
                  id="minPrice"
                  name="minPrice"
                  min="0"
                  max="1000000"
                  value={minPrice}
                  onChange={handleMinSliderChange}
                />
              </section>
              <section>
                <span>
                  <label htmlFor="maxPrice">
                    <p>Max</p>
                  </label>
                </span>
                <input
                  type="number"
                  id="maxPrice"
                  name="maxPrice"
                  min="0"
                  max="1000000"
                  value={maxPrice}
                  onChange={handleMaxSliderChange}
                />
              </section>
            </div>
            <input
              type="text"
              value={searchTerm}
              placeholder="Chercher une machine"
              className="search__input"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="button" onClick={handleSearch}>
              Chercher
            </button>
          </div>
        </div>
        <div className="results__area">
          <div className="result__item">
            {offersLoading && (
              <div className="loading">
                <div className="spinner"></div>
              </div>
            )}

            {offers && offers?.length > 0 ? (
              offers.map((offer) => (
                <SearchItem
                  key={offer._id}
                  id={offer._id}
                  image={offer?.image}
                  images={offer?.images}
                  title={offer.title}
                  location={offer.city}
                  category={offer.category}
                  price={offer.price}
                  type={offer.type}
                  subcategory={offer.subcategory}
                  publishedAt={offer.createdAt}
                />
              ))
            ) : offersLoading || error ? (
              <div className="aucun__resultat">
                <h3>{error}</h3>
              </div>
            ) : offers && offers.length === 0 && !offersLoading ? (
              <div className="aucun__resultat">
                <h3>Aucun résultat n'a été trouvé</h3>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="paginate__wrapper">
            {offers && offers.length > 0 && pages >= 2 && (
              <Paginate pages={pages} page={page} />
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default SearchScreen;
