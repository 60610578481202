import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import "./EmailSentResetScreen.css";

import axios from "axios";

const EmailSentResetScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const offerDetails = useSelector((state) => state.offerDetails);
  const { offer } = offerDetails;

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // useEffect(() => {

  // }, []);
  return (
    <div className="emailsentscreen">
      <div className="email__sent">
        <div>
          <p>
            Un email vous a été envoyé. Merci de suivre les instructions.
            Veuillez vérifier votre boîte de réception, y compris le dossier de
            spam.
          </p>
          <p>
            ⚠️ Assurez-vous de cliquer sur le lien de réinitialisation dans les
            20 minutes, car il expire après ce délai. ⚠️
          </p>
        </div>
        {/* <i className="fa-solid fa-envelope-circle-check"></i> */}
        <i className="fa-solid fa-paper-plane"></i>
      </div>
    </div>
  );
};

export default EmailSentResetScreen;
