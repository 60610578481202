import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useParams, useNavigate } from "react-router-dom";
import "./ResetPasswordScreen.css";
import axios from "axios";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { token } = useParams();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setError("Le mot de passe doit comporter au moins 8 caractères.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await axios.post("/api/users/passwordchange", {
        password,
        token,
      });

      const statusCode = response.status;

      if (statusCode === 401) {
        setError("Le lien de réinitialisation est expiré");
      } else {
        navigate("/login");
      }
    } catch (error) {
      // Handle other errors if needed
      if (error?.response?.data?.message === "Invalid Token") {
        setError(
          "Le lien de réinitialisation est expiré. Veuillez recommencer le processus"
        );
      } else {
        console.error("Error during password change:", error);
      }
    }
  };
  useEffect(() => {
    document.title = "réinitialiser le mot de passe | L'matarian";

    if (userInfo || !token) {
      navigate("/");
    }
  }, [userInfo, token, navigate]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="reset__instructions__wrapper"
    >
      <div className="makenewoffer">
        <h1>Réinitialiser le mot de passe</h1>
        <div className="newoffer__details">
          <div className="newoffer__name">
            <label>Entrer votre nouveau mot de passe</label>
            <input
              className=""
              type="password"
              value={password}
              placeholder="Nouveau mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="newoffer__name">
            <label>Confirmez votre nouveau mot de passe</label>
            <input
              className=""
              type="password"
              value={confirmPassword}
              placeholder="Confirmez nouveau mot de passe"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {error && <p className="password__error">{error}</p>}
        </div>
        <button
          type="button"
          onClick={handlePasswordReset}
          className="reset__instructions__button"
        >
          confirmer
        </button>
      </div>
    </motion.div>
  );
};

export default ResetPasswordScreen;
