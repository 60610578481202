import React from "react"

const GrosOeuvre = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>
      <option value={"bétonnière"}>bétonnière</option>
      <option value={"auto bétonnière"}>auto bétonnière</option>
      <option value={"vibreurs"}>vibreurs</option>
      <option value={"nettoyeuse (karcher)"}>nettoyeuse (karcher)</option>
      <option value={"dégageur"}>dégageur</option>
      <option value={"marteau piqueur"}>marteau piqueur</option>
      <option value={"marteau perforateur"}>marteau perforateur</option>
      <option value={"épandeuse de bitume"}>épandeuse de bitume</option>
      <option value={"centrale a béton "}>centrale a béton </option>
      <option value={"concasseur broyeur"}>concasseur broyeur</option>
      <option value={"scie sauteuse ou scie circulaire"}>
        scie sauteuse ou scie circulaire
      </option>
      <option value={"scie a sol"}>scie a sol</option>
      <option value={"sableuse"}>sableuse</option>
      <option value={"hélicoptère à béton"}>hélicoptère à béton</option>
      <option value={"ponceuse et raboteuse"}>ponceuse et raboteuse</option>
      <option value={"malaxeur"}>malaxeur</option>
      <option value={"aspirateur"}>aspirateur</option>
      <option value={"meuleuse"}>meuleuse</option>
    </select>
  )
}
export default GrosOeuvre
