import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./ContactUsScreen.css";
import { loginUser } from "../redux/actions/userActions";
import Footer from "../components/Footer";

const ContactUsScreen = () => {
  const [email, setEmail] = useState("");
  const [contactMsg, setContactMsg] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const { search } = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    document.title = "Contactez-nous | l'matarian";
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  return (
    <div className="contactusscreen">
      <div className="contactusscreen__inside">
        <h1 className="login__header">Contactez nous</h1>
        {error && <div className="signIn__error">{error}</div>}
        {loading && <div className="spinner2"></div>}
        <form onSubmit={handleSubmit} className="form__elements">
          <div className="form__insides">
            <div className="username__section">
              <label>Email</label>
              <input
                type="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="contact_text_area">
            <label>Message</label>
            <textarea
              type="text"
              value={contactMsg}
              placeholder="Ecrire votre message"
              onChange={(e) => setContactMsg(e.target.value)}
            />
          </div>
          <button type="submit" className="login__btn">
            Envoyer
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsScreen;
