import React from "react";

const BaseVie = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>

      <option value={"base vie "}>base vie </option>

      <option value={"bungalow"}>bungalow</option>
      <option value={"baraque"}>baraque</option>
      <option value={"container"}>container</option>

      <option value={"wc chantier"}>wc chantier</option>
      <option value={"citerne à carburant"}>citerne à carburant</option>
      <option value={"citerne à eau"}>citerne à eau</option>
    </select>
  );
};

export default BaseVie;
