import React, { useState } from "react";
import "./SearchItem.css";
import { Link } from "react-router-dom";
import default_pic from "../assets/default_pic.png";

const SearchItem = ({
  id,
  image,
  images,
  title,
  location,
  category,
  subcategory,
  type,
  price,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="searchitem">
      {imageError || images?.length === 0 ? (
        <img src={default_pic} alt="Fallback Icon" />
      ) : (
        <img
          src={images[0]}
          alt={`image_de_la_machine${id}`}
          onError={handleImageError}
        />
      )}
      <div className="machine__infos">
        <div className="machine__name">
          <h3>{title}</h3>
        </div>

        <div className="machine__address">
          <i className="fa-solid fa-location-dot"></i>
          <p>{location}</p>
        </div>
        <div className="machine__address">
          <i className="fa-solid fa-cubes-stacked"></i>
          <p>{category}</p>
        </div>

        <div className="machine__address">
          <i className="fa-solid fa-code-branch"></i>
          <p>{subcategory}</p>
        </div>
        <div className="machine__address">
          <i className="fa-solid fa-truck-moving"></i>
          <p>{type === "rent" ? "Location" : "Vente"}</p>
        </div>

        <div className="machine__address">
          <i className="fa-solid fa-tag"></i>
          <p>
            {type === "rent"
              ? price + " DH / Jour"
              : type === "buy"
              ? price + " DH"
              : ""}
          </p>
        </div>

        <div className="machine__available">
          <div>
            <Link to={`/offer/${id}`} className="offer__link">
              détails
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
