import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./RegisterScreen.css";
import { registerUser } from "../redux/actions/userActions";
import Footer from "../components/Footer";

const RegisterScreen = () => {
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== newPassword) {
      setMessage("Password does not match");
    } else {
      setMessage(null);
      dispatch(
        registerUser({ userName, fullName: name, email, password, phoneNumber })
      );
    }
  };

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, error, loading } = userRegister;

  useEffect(() => {
    document.title = "Créer un compte | L'matarian";

    if (userInfo) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  return (
    <div className="registerscreen">
      <div className="registerscreen__inside">
        <h1 className="register__header">Créer un compte</h1>
        {loading && <div className="spinner2"></div>}

        <form onSubmit={handleSubmit} className="form__elements">
          <div className="username__section_register">
            <label>Nom d'utilisateur</label>
            <input
              type="text"
              placeholder="Entrer votre nom d'utilisateur"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="username__section_register">
            <label>Nom complet</label>
            <input
              type="text"
              placeholder="Entrer votre nom complet"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="username__section_register">
            <label>Email</label>
            <input
              type="email"
              placeholder="Entrer votre email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="username__section_register">
            <label>Numéro du téléphone</label>
            <input
              type="number"
              maxLength={10}
              placeholder="Entrer vote numéro"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
          <div className="username__section_register">
            <label>Mot de passe</label>
            <input
              type="password"
              placeholder="Entrer votre mot de passe"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="username__section_register">
            <label>Confirmation du Mot de passe</label>
            <input
              type="password"
              placeholder="Confirmer votre mot de passe"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>

          <p className="login__link">
            Vous avez déja un compte ? <Link to="/login">Se connecter</Link>
          </p>
          <button type="submit" className="register__btn">
            Suivant
          </button>
          {error && <div className="signIn__error">{error}</div>}

          {message && <div className="signIn__error">{message}</div>}
        </form>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RegisterScreen;
