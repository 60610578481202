import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/userActions";
import "./ThirdStepScreen.css";
import OfferSteps from "../components/OfferSteps";

const ThirdStepScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    document.title = "Se Connecter | l'matarian";
    if (userInfo) {
      navigate("/offersummary");
    }
  }, [userInfo, navigate]);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  return (
    <div className="thirdstepscreen">
      <div className="thirdstepscreen__inside">
        <h1>Créer votre annonce. C'est Facile!</h1>
        <OfferSteps step1 step2 step3 />
        {error && <div className="signIn__error">{error}</div>}
        {loading && <div className="spinner2"></div>}
        <div className="form__elements">
          <div className="form__insides">
            <div className="username__section">
              <label>Email</label>
              <input
                type="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="username__section">
              <label>Mot de passe</label>
              <input
                type="password"
                placeholder="Enter votre mot de passe"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="login__links_wrapper">
            <p className="login__link">
              Vous n'avez pas encore de compte ? {"  "}
              <Link to="/register">Créez-en un !</Link>
            </p>
            <p className="login__link">
              Mot de pass oublié ?{"  "}
              <Link to="/passinstruct">Réinitialiser le mot de passe !</Link>
            </p>
          </div>
          <div className="step3__suivant">
            <Link to={"/makeoffer/step2"} className="continue__btn">
              Retour
            </Link>
            <button className="continue__btn" onClick={handleClick}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdStepScreen;
