export const OFFER_CREATE_REQUEST = "OFFER_CREATE_REQUEST";
export const OFFER_CREATE_SUCCESS = "OFFER_CREATE_SUCCESS";
export const OFFER_CREATE_FAIL = "OFFER_CREATE_FAIL";
export const OFFER_CREATE_RESET = "OFFER_CREATE_RESET";

export const OFFER_SAVE_TITLE_AND_CATEGORY = "OFFER_SAVE_TITLE_AND_CATEGORY";
export const OFFER_SAVE_CITY_AND_DESCRIPTION =
  "OFFER_SAVE_CITY_AND_DESCRIPTION";

export const OFFER_DETAILS_REQUEST = "OFFER_DETAILS_REQUEST";
export const OFFER_DETAILS_SUCCESS = "OFFER_DETAILS_SUCCESS";
export const OFFER_DETAILS_FAIL = "OFFER_DETAILS_FAIL";

export const OFFER_LIST_MY_REQUEST = "OFFER_LIST_MY_REQUEST";
export const OFFER_LIST_MY_SUCCESS = "OFFER_LIST_MY_SUCCESS";
export const OFFER_LIST_MY_FAIL = "OFFER_LIST_MY_FAIL";
export const OFFER_LIST_MY_RESET = "OFFER_LIST_MY_RESET";

export const OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST =
  "OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST";
export const OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS =
  "OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS";
export const OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL =
  "OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL";
export const OFFER_LIST_TRAVAUX_EN_HAUTEUR_RESET =
  "OFFER_LIST_TRAVAUX_EN_HAUTEUR_RESET";

export const OFFER_LIST_TERRASSEMENT_REQUEST =
  "OFFER_LIST_TERRASSEMENT_REQUEST";
export const OFFER_LIST_TERRASSEMENT_SUCCESS =
  "OFFER_LIST_TERRASSEMENT_SUCCESS";
export const OFFER_LIST_TERRASSEMENT_FAIL = "OFFER_LIST_TERRASSEMENT_FAIL";
export const OFFER_LIST_TERRASSEMENT_RESET = "OFFER_LIST_TERRASSEMENT_RESET";

export const OFFER_LIST_LEVAGE_REQUEST = "OFFER_LIST_LEVAGE_REQUEST";
export const OFFER_LIST_LEVAGE_SUCCESS = "OFFER_LIST_LEVAGE_SUCCESS";
export const OFFER_LIST_LEVAGE_FAIL = "OFFER_LIST_LEVAGE_FAIL";
export const OFFER_LIST_LEVAGE_RESET = "OFFER_LIST_LEVAGE_RESET";

export const OFFER_LIST_TRANSPORT_REQUEST = "OFFER_LIST_TRANSPORT_REQUEST";
export const OFFER_LIST_TRANSPORT_SUCCESS = "OFFER_LIST_TRANSPORT_SUCCESS";
export const OFFER_LIST_TRANSPORT_FAIL = "OFFER_LIST_TRANSPORT_FAIL";
export const OFFER_LIST_TRANSPORT_RESET = "OFFER_LIST_TRANSPORT_RESET";

export const OFFER_DELETE_MY_REQUEST = "OFFER_DELETE_MY_REQUEST";
export const OFFER_DELETE_MY_SUCCESS = "OFFER_DELETE_MY_SUCCESS";
export const OFFER_DELETE_MY_FAIL = "OFFER_DELETE_MY_FAIL";
export const OFFER_DELETE_MY_RESET = "OFFER_DELETE_MY_RESET";

export const OFFER_UPDATE_MY_REQUEST = "OFFER_UPDATE_MY_REQUEST";
export const OFFER_UPDATE_MY_SUCCESS = "OFFER_UPDATE_MY_SUCCESS";
export const OFFER_UPDATE_MY_FAIL = "OFFER_UPDATE_MY_FAIL";
export const OFFER_UPDATE_MY_RESET = "OFFER_UPDATE_MY_RESET";

export const OFFER_LIST_REQUEST = "OFFER_LIST_REQUEST";
export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAIL = "OFFER_LIST_FAIL";

export const OFFER_TOTAL_REQUEST = "OFFER_TOTAL_REQUEST";
export const OFFER_TOTAL_SUCCESS = "OFFER_TOTAL_SUCCESS";
export const OFFER_TOTAL_FAIL = "OFFER_TOTAL_FAIL";

export const OFFER_LIST_UNAPPROVED_REQUEST = "OFFER_LIST_UNAPPROVED_REQUEST";
export const OFFER_LIST_UNAPPROVED_SUCCESS = "OFFER_LIST_UNAPPROVED_SUCCESS";
export const OFFER_LIST_UNAPPROVED_FAIL = "OFFER_LIST_UNAPPROVED_FAIL";

export const OFFER_LIST_BUY_REQUEST = "OFFER_LIST_BUY_REQUEST";
export const OFFER_LIST_BUY_SUCCESS = "OFFER_LIST_BUY_SUCCESS";
export const OFFER_LIST_BUY_FAIL = "OFFER_LIST_BUY_FAIL";

export const OFFER_LIST_RENT_REQUEST = "OFFER_LIST_RENT_REQUEST";
export const OFFER_LIST_RENT_SUCCESS = "OFFER_LIST_RENT_SUCCESS";
export const OFFER_LIST_RENT_FAIL = "OFFER_LIST_RENT_FAIL";

export const OFFER_APPROVE_REQUEST = "OFFER_APPROVE_REQUEST";
export const OFFER_APPROVE_SUCCESS = "OFFER_APPROVE_SUCCESS";
export const OFFER_APPROVE_FAIL = "OFFER_APPROVE_FAIL";

export const OFFER_ADMIN_DELETE_REQUEST = "OFFER_ADMIN_DELETE_REQUEST";
export const OFFER_ADMIN_DELETE_SUCCESS = "OFFER_ADMIN_DELETE_SUCCESS";
export const OFFER_ADMIN_DELETE_FAIL = "OFFER_ADMIN_DELETE_FAIL";
