import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./OfferScreen.css";
import default_pic from "../assets/default_pic.png";

//assets
import user from "../assets/user.png";
import { getOfferDetails } from "../redux/actions/offerActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";

const OfferScreen = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const dispatch = useDispatch();
  const { id } = useParams();

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const offerDetails = useSelector((state) => state.offerDetails);
  const { offer, loading: offerLoading } = offerDetails;

  useEffect(() => {
    if (offer && id !== offer._id) {
      dispatch(getOfferDetails(id));
    }
    let matarian = "L'matarian";
    document.title =
      offer && offer.title ? offer.title + ` | ${matarian}` : matarian;
  }, [dispatch, offer, id]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % offer?.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + offer?.images.length) % offer?.images.length
    );
  };
  return (
    <div className="offerscreen___wrapper">
      {offerLoading ? (
        <div className="offerscreen__empty">
          <div className="offer__loading">
            <div className="offer__spinner"></div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.3 }}
          className="offerscreen"
        >
          {offer && (
            <>
              <div className="offerscreen__images">
                <div className="prix__type">
                  <div>
                    <p>
                      {offer.type === "rent"
                        ? offer.price + "DH / jour"
                        : offer.type === "buy"
                        ? offer.price + " DH"
                        : null}
                    </p>
                  </div>
                  <div
                    className={
                      offer.type === "rent"
                        ? "rent__class"
                        : offer.type === "buy"
                        ? "buy__class"
                        : ""
                    }
                  >
                    <p>
                      {offer.type === "rent"
                        ? "Location"
                        : offer.type === "buy"
                        ? "Vente"
                        : ""}
                    </p>
                  </div>
                </div>
                {imageError || offer.images.length === 0 ? (
                  <img src={default_pic} alt="Fallback Icon" />
                ) : (
                  <div className="images__class">
                    <img
                      src={offer?.images[currentImageIndex]}
                      alt={`${offer?.images[currentImageIndex]}`}
                      onError={handleImageError}
                    />
                    <div className="carroussel__leftRight">
                      <button onClick={prevImage}>
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <button onClick={nextImage}>
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="offer__details">
                <div className="offer__title">
                  <p>{offer.title}</p>
                  <div className="offer__moreinfo">
                    <div className="offer__location">
                      <i className="fa-solid fa-location-dot"></i>
                      <p>{offer.city}</p>
                    </div>
                    <div className="offer__time">
                      <i className="fa-solid fa-calendar-days"></i>
                      {offer.createdAt && (
                        <p>
                          {offer.createdAt.toString().slice(0, 10)} à{"  "}
                          {offer.createdAt.toString().slice(11, 16)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="offer__user">
                  <div className="picture__name">
                    <img src={user} alt="user" />
                    {offer.user && <p>{offer.user.fullName}</p>}
                  </div>
                  {offer.user && (
                    <p className="offer__contact">0{offer.user.phoneNumber}</p>
                  )}
                </div>
                <div className="offer__description">
                  <h2>Description</h2>
                  <p>{offer.description}</p>
                </div>
              </div>
            </>
          )}
        </motion.div>
      )}
      <Footer />
    </div>
  );
};

export default OfferScreen;
