import React from "react";
import { Link } from "react-router-dom";
import "./OfferRow.css";

const OfferRow = ({ title, city, identify, clickDelete, createdAt }) => {
  return (
    <tr>
      <td>
        <Link to={`/offer/${identify}`} className="offer_row_title">
          {title}
        </Link>
      </td>
      <td>{city}</td>
      <td>{createdAt.toString().slice(0, 10)}</td>
      <td>
        <Link to={`/myoffer/update/${identify}`}>
          <i className="fa-solid fa-wrench"></i>
        </Link>
      </td>
      <td>
        <i
          className="fa-solid fa-trash trash_icon"
          onClick={() => clickDelete(identify)}
        ></i>
      </td>
    </tr>
  );
};

export default OfferRow;
