import axios from "axios";

import {
  OFFER_CREATE_REQUEST,
  OFFER_CREATE_SUCCESS,
  OFFER_CREATE_FAIL,
  OFFER_DETAILS_FAIL,
  OFFER_DETAILS_SUCCESS,
  OFFER_DETAILS_REQUEST,
  OFFER_LIST_MY_REQUEST,
  OFFER_LIST_MY_SUCCESS,
  OFFER_LIST_MY_FAIL,
  OFFER_LIST_FAIL,
  OFFER_LIST_SUCCESS,
  OFFER_LIST_REQUEST,
  OFFER_LIST_UNAPPROVED_FAIL,
  OFFER_LIST_UNAPPROVED_SUCCESS,
  OFFER_LIST_UNAPPROVED_REQUEST,
  OFFER_DELETE_MY_REQUEST,
  OFFER_DELETE_MY_SUCCESS,
  OFFER_DELETE_MY_FAIL,
  OFFER_UPDATE_MY_REQUEST,
  OFFER_UPDATE_MY_SUCCESS,
  OFFER_UPDATE_MY_FAIL,
  OFFER_SAVE_TITLE_AND_CATEGORY,
  OFFER_SAVE_CITY_AND_DESCRIPTION,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL,
  OFFER_LIST_LEVAGE_REQUEST,
  OFFER_LIST_LEVAGE_SUCCESS,
  OFFER_LIST_LEVAGE_FAIL,
  OFFER_LIST_TERRASSEMENT_REQUEST,
  OFFER_LIST_TERRASSEMENT_SUCCESS,
  OFFER_LIST_TERRASSEMENT_FAIL,
  OFFER_LIST_TRANSPORT_REQUEST,
  OFFER_LIST_TRANSPORT_SUCCESS,
  OFFER_LIST_TRANSPORT_FAIL,
  OFFER_TOTAL_FAIL,
  OFFER_TOTAL_REQUEST,
  OFFER_TOTAL_SUCCESS,
  OFFER_LIST_RENT_REQUEST,
  OFFER_LIST_RENT_SUCCESS,
  OFFER_LIST_RENT_FAIL,
  OFFER_LIST_BUY_REQUEST,
  OFFER_LIST_BUY_SUCCESS,
  OFFER_LIST_BUY_FAIL,
  OFFER_APPROVE_REQUEST,
  OFFER_APPROVE_SUCCESS,
  OFFER_APPROVE_FAIL,
  OFFER_ADMIN_DELETE_REQUEST,
  OFFER_ADMIN_DELETE_SUCCESS,
  OFFER_ADMIN_DELETE_FAIL,
} from "../constants/offerConstants";
import { logout } from "./userActions";

export const createOffer = (offer) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/offers`, offer, config);

    dispatch({
      type: OFFER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const saveOfferTitleAndCategory = (data) => (dispatch) => {
  dispatch({
    type: OFFER_SAVE_TITLE_AND_CATEGORY,
    payload: data,
  });

  localStorage.setItem("titleAndCategory", JSON.stringify(data));
};

export const saveOfferCityAndDescription = (data) => (dispatch) => {
  dispatch({
    type: OFFER_SAVE_CITY_AND_DESCRIPTION,
    payload: data,
  });
  localStorage.setItem("cityAndDescription", JSON.stringify(data));
};

export const getOfferDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFER_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/offers/${id}`);

    dispatch({
      type: OFFER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listMyOffers = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFER_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/offers/myoffers/${id}`, config);

    dispatch({
      type: OFFER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_LIST_MY_FAIL,
      payload: message,
    });
  }
};

export const listOffersWithFilters =
  ({
    pageNumber = "",
    city,
    category,
    subCategory,
    offerType,
    minPrice,
    maxPrice,
    searchTerm,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFER_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `/api/offers/search/all?pageNumber=${pageNumber}&keyword1=${city}&keyword2=${category}&keyword3=${subCategory}&keyword4=${offerType}&keyword5=${minPrice}&keyword6=${maxPrice}&keyword7=${searchTerm}`
      );

      dispatch({
        type: OFFER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_LIST_FAIL,
        payload: message,
      });
    }
  };

export const listUnapprovedOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFER_LIST_UNAPPROVED_REQUEST,
    });

    const { data } = await axios.get(`/api/offers/all/unapproved`);

    dispatch({
      type: OFFER_LIST_UNAPPROVED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_LIST_UNAPPROVED_FAIL,
      payload: message,
    });
  }
};

export const offersTotal = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFER_TOTAL_REQUEST,
    });

    const { data } = await axios.get(`/api/offers/total/all`);

    dispatch({
      type: OFFER_TOTAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_TOTAL_FAIL,
      payload: message,
    });
  }
};

export const listOffersforTravauxEnHauteur =
  (keyword1 = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST,
      });

      const { data } = await axios.get(
        `/api/offers/search/travaux?keyword=${keyword1}`
      );

      dispatch({
        type: OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL,
        payload: message,
      });
    }
  };

export const listOffersforLevage =
  (keyword1 = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFER_LIST_LEVAGE_REQUEST,
      });

      const { data } = await axios.get(
        `/api/offers/search/levage?keyword=${keyword1}`
      );

      dispatch({
        type: OFFER_LIST_LEVAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_LIST_LEVAGE_FAIL,
        payload: message,
      });
    }
  };

export const listOffersforTerrassement =
  (keyword1 = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFER_LIST_TERRASSEMENT_REQUEST,
      });

      const { data } = await axios.get(
        `/api/offers/search/levage?keyword=${keyword1}`
      );

      dispatch({
        type: OFFER_LIST_TERRASSEMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_LIST_TERRASSEMENT_FAIL,
        payload: message,
      });
    }
  };

export const listOffersforTransport =
  (keyword1 = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: OFFER_LIST_TRANSPORT_REQUEST,
      });

      const { data } = await axios.get(
        `/api/offers/search/transport?keyword=${keyword1}`
      );

      dispatch({
        type: OFFER_LIST_TRANSPORT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_LIST_TRANSPORT_FAIL,
        payload: message,
      });
    }
  };

export const listBuyOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFER_LIST_BUY_REQUEST,
    });

    const { data } = await axios.get(`/api/offers/all/buy/`);

    dispatch({
      type: OFFER_LIST_BUY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_LIST_BUY_FAIL,
      payload: message,
    });
  }
};

export const listRentOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFER_LIST_RENT_REQUEST,
    });

    const { data } = await axios.get(`/api/offers/all/rent/`);

    dispatch({
      type: OFFER_LIST_RENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_LIST_RENT_FAIL,
      payload: message,
    });
  }
};

export const deleteMyOffer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFER_DELETE_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/offers/${id}`, config);

    dispatch({
      type: OFFER_DELETE_MY_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_DELETE_MY_FAIL,
      payload: message,
    });
  }
};

export const UpdateOffer =
  (offerId, offerDetails) => async (dispatch, getState) => {
    try {
      dispatch({
        type: OFFER_UPDATE_MY_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/offers/myoffers/update/${offerId}/`,
        offerDetails,
        config
      );

      dispatch({
        type: OFFER_UPDATE_MY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_UPDATE_MY_FAIL,
        payload: message,
      });
    }
  };

export const approveOffer =
  (offerId, offerApproved) => async (dispatch, getState) => {
    try {
      dispatch({
        type: OFFER_APPROVE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/offers/admin/approve`,
        { offerId, offerApproved },
        config
      );

      dispatch({
        type: OFFER_APPROVE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: OFFER_APPROVE_FAIL,
        payload: message,
      });
    }
  };

export const deleteUnapprovedOffer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OFFER_ADMIN_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/offers/admin/delete/${id}`, config);

    dispatch({
      type: OFFER_ADMIN_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: OFFER_ADMIN_DELETE_FAIL,
      payload: message,
    });
  }
};
