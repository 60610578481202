import "./MakeRequestScreen.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MakeOfferScreen.css";
import { motion } from "framer-motion";
import pictures from "../assets/image2.png";

//Sub-Categories
import AllCities from "../components/AllCities";
import AllCategories from "../components/AllCategories";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import BaseVie from "../components/subcatgories/BaseVie";
import { createOffer } from "../redux/actions/offerActions";
import { OFFER_CREATE_RESET } from "../redux/constants/offerConstants";
import axios from "axios";
import Footer from "../components/Footer";

const MakeRequestScreen = () => {
  const [offerName, setOfferName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [city, setCity] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offerCreate = useSelector((state) => state.offerCreate);
  const { success, error, loading } = offerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleClick = (e) => {
    e.preventDefault();

    dispatch(
      createOffer({
        user: userInfo._id,
        title: offerName,
        city,
        category,
        subcategory: subCategory,

        description: desc,
      })
    );
  };

  useEffect(() => {
    document.title = "Création d'annonce";
    if (success) {
      navigate("/offersuccess");
      dispatch({
        type: OFFER_CREATE_RESET,
      });
    }
  }, [navigate, success, dispatch]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="makenewrequest__wrapper"
    >
      <div className="makenewoffer">
        <h1>Créer une demande</h1>

        <div className="makerequest__details">
          <div className="newoffer__name">
            <label>Titre de la demande</label>
            <input
              className=""
              type="text"
              value={offerName}
              onChange={(e) => setOfferName(e.target.value)}
            />
          </div>
          <div className="newoffer__name">
            <label>Catégorie</label>
            <AllCategories category={category} setCategory={setCategory} />
          </div>

          <div className="newoffer__name">
            {category === "Travaux en hauteur" ? (
              <>
                <label>Sous-catégorie</label>
                <TravauxEnHateur
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Levage et manutention" ? (
              <>
                <label>Sous-catégorie</label>
                <LevageEtManutention
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Terrassement et excavation" ? (
              <>
                <label>Sous-catégorie</label>
                <TerrassementEtExcavation
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Transport et logistique" ? (
              <>
                <label>Sous-catégorie</label>
                <TransportEtLogistique
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Gros oeuvre et route" ? (
              <>
                <label>Sous-catégorie</label>
                <GrosOeuvre
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "énergie et pompage" ? (
              <>
                <label>Sous-catégorie</label>
                <EnergieEtPompage
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Equipement de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <EquipementDeChantier
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Base vie et installation de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <BaseVie
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="newoffer__name">
            <label>Ville</label>
            <AllCities city={city} setCity={setCity} />
          </div>

          <div className="offer__name__step2">
            <label>Déscription</label>
            <textarea
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
        </div>
        {error && <h4 className="offer__error">{error}</h4>}
        <button
          type="button"
          onClick={handleClick}
          className="createoffer__btn"
        >
          Créer cette demande
        </button>
      </div>
      {/* <Footer /> */}
    </motion.div>
  );
};

export default MakeRequestScreen;
