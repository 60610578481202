import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import user from "../assets/user.png";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { getOfferDetails, UpdateOffer } from "../redux/actions/offerActions";
import "./UpdateMyOffer.css";

//

import pictures from "../assets/image1.png";

//Sub-Categories
import AllCities from "../components/AllCities";
import AllCategories from "../components/AllCategories";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import BaseVie from "../components/subcatgories/BaseVie";
import { createOffer } from "../redux/actions/offerActions";
import {
  OFFER_CREATE_RESET,
  OFFER_UPDATE_MY_RESET,
} from "../redux/constants/offerConstants";
import axios from "axios";

const UpdateMyOffer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const offerDetails = useSelector((state) => state.offerDetails);
  const { offer } = offerDetails;

  const [image, setImage] = useState("excav");
  const [offerName, setOfferName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [city, setCity] = useState("");
  const [uploading, setUploading] = useState(false);

  const [desc, setDesc] = useState("");

  const navigate = useNavigate();

  const offerUpdate = useSelector((state) => state.offerUpdate);
  const { updateOfferSuccess, error, updateOfferLoading } = offerUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleUploadChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      UpdateOffer(offer._id, {
        title: offerName,
        description: desc,
      })
    );
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  useEffect(() => {
    document.title = "Mise à jour de l'annonce | L'matarian";
    if (offer && id !== offer._id) {
      dispatch(getOfferDetails(id));
    }
    if (updateOfferSuccess) {
      navigate("/profile");
      dispatch({
        type: OFFER_UPDATE_MY_RESET,
      });
    }
  }, [dispatch, navigate, updateOfferSuccess, offer, id]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="makenewoffer__wrapper"
    >
      <div className="makenewoffer">
        <h1>Modifier mon annonce</h1>
        <div className="image__upload">
          <div className="inside__upload">
            <p>Ajouter</p>
            <img src={pictures} alt="pictures" />
            <input type="file" onChange={uploadFileHandler} />
          </div>
          <div className="conseil">
            <div className="conseil__inside">
              <i className="fa-solid fa-triangle-exclamation"></i>
              <h4>Conseils</h4>
            </div>
            <ul>
              <li>
                Une annonce avec des images indicatifs est plus visible aux
                utilisateurs
              </li>
              <li>Remplir tous les cases nécessaires</li>
            </ul>
          </div>
        </div>
        <div className="newoffer__details">
          <div className="newoffer__name">
            <label>Titre de l'offre</label>
            <input
              className=""
              type="text"
              value={offerName}
              onChange={(e) => setOfferName(e.target.value)}
            />
          </div>
          <div className="newoffer__name">
            <label>Catégorie</label>
            <AllCategories category={category} setCategory={setCategory} />
          </div>

          <div className="newoffer__name">
            {category === "Travaux en hauteur" ? (
              <>
                <label>Sous-catégorie</label>
                <TravauxEnHateur
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Levage et manutention" ? (
              <>
                <label>Sous-catégorie</label>
                <LevageEtManutention
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Terrassement et excavation" ? (
              <>
                <label>Sous-catégorie</label>
                <TerrassementEtExcavation
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Transport et logistique" ? (
              <>
                <label>Sous-catégorie</label>
                <TransportEtLogistique
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Gros oeuvre et route" ? (
              <>
                <label>Sous-catégorie</label>
                <GrosOeuvre
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "énergie et pompage" ? (
              <>
                <label>Sous-catégorie</label>
                <EnergieEtPompage
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Equipement de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <EquipementDeChantier
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Base vie et installation de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <BaseVie
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="newoffer__name">
            <label>Ville</label>
            <AllCities city={city} setCity={setCity} />
          </div>

          <div className="newoffer__name">
            <label>Déscription</label>
            <input
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
        </div>
        {error && <h4 className="offer__error">{error}</h4>}
        <button
          type="button"
          onClick={handleClick}
          className="createoffer__btn"
        >
          Mettre à jour mon annonce
        </button>
      </div>
    </motion.div>
  );
};

export default UpdateMyOffer;
