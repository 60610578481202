import React, { useEffect } from "react";
import "./OfferSuccessScreen.css";
import check from "../assets/check.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const OfferSuccessScreen = () => {
  const offerCreate = useSelector((state) => state.offerCreate);
  const { success } = offerCreate;

  const cancelOffer = () => {};

  useEffect(() => {
    document.title = "Succés de l'offre | L'matarian";
  }, []);
  return (
    <div className="offersuccessscreen">
      <div className="offersuccess__inside">
        <p>Votre offre a été crée avec succès</p>
        <img src={check} alt="check" />
      </div>
      <Link className="goback__offer" to="/newoffer">
        Créer une autre annonce
      </Link>
    </div>
  );
};

export default OfferSuccessScreen;
