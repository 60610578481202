import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/actions/userActions";

import "./SideDrawer.css";

const SideDrawer = ({ click, show }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const sideDrawerClass = ["sidedrawer"];

  if (show) {
    sideDrawerClass.push("show");
  }

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className={sideDrawerClass.join(" ")}>
      <ul className="sidedrawer__links" onClick={click}>
        <li>
          <Link to="/">Accueil</Link>
        </li>
        <li>
          <Link to="/discover/page/1">Découvrir</Link>
        </li>
        {userInfo && !userInfo.isAdmin ? (
          <>
            <li>
              <Link to="/profile">Profile</Link>
            </li>

            <li className="sidedrawer__announce">
              <Link to="/newoffer">
                <u>Nouvelle annonce ?</u>
              </Link>
            </li>
            <li className="sidedrawer__logout" onClick={handleLogout}>
              Déconnexion
            </li>
          </>
        ) : userInfo && userInfo.isAdmin ? (
          <>
            <li>
              <Link to="/profile">Profile</Link>
            </li>

            <li className="sidedrawer__announce">
              <Link to="/newoffer">
                <u>Nouvelle annonce ?</u>
              </Link>
            </li>
            <li>
              <Link to="/dash/global">Dashboard</Link>
            </li>
            <li className="sidedrawer__logout" onClick={handleLogout}>
              Déconnexion
            </li>
          </>
        ) : (
          <>
            <li className="sidedrawer__announce">
              <Link to="/makeoffer/step1">
                <u>Vous avez une annonce?</u>
              </Link>
            </li>
            <li>
              <Link to="/login">Connéxion</Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SideDrawer;
