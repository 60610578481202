import React from "react";

const EquipementDeChantier = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>

      <option value={"madriers"}>madriers</option>

      <option value={"panneaux"}>panneaux</option>
      <option value={"poutrelle"}>poutrelle</option>
      <option value={"étais (bois ou métallique)"}>
        étais (bois ou métallique)
      </option>
      <option value={"tiges"}>tiges</option>
      <option value={"coffrage doka complet"}>coffrage doka complet</option>
      <option value={"coffrage Péri complet"}>coffrage Péri complet</option>
      <option value={"coffrage grimpant"}>coffrage grimpant</option>
      <option value={"coffrage modulaire"}>coffrage modulaire</option>
      <option value={"tour d’étaiement"}>tour d’étaiement</option>
      <option value={"tour d’escalier"}>tour d’escalier</option>
      <option value={"barrière"}>barrière</option>
    </select>
  );
};

export default EquipementDeChantier;
