import React, { useEffect, useState } from "react";
import "./SecondStepScreen.css";
import OfferSteps from "../components/OfferSteps";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveOfferCityAndDescription } from "../redux/actions/offerActions";
import AllCities from "../components/AllCities";

const SecondStepScreen = () => {
  const titleAndCategoryStorage = localStorage.getItem("titleAndCategory");
  const titleAndCategoryParsed = JSON.parse(titleAndCategoryStorage);
  let type = titleAndCategoryParsed?.type;

  const cityAndDescriptionStorage = localStorage.getItem("cityAndDescription");
  const cityAndDescriptionParsed = JSON.parse(cityAndDescriptionStorage);

  const [city, setCity] = useState(
    cityAndDescriptionParsed ? cityAndDescriptionParsed.city : ""
  );
  const [description, setDescription] = useState(
    cityAndDescriptionParsed ? cityAndDescriptionParsed.description : ""
  );
  const [price, setPrice] = useState(
    cityAndDescriptionParsed ? cityAndDescriptionParsed.price : 0
  );
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    if (e.key === "-" || e.key === "e" || e.key === "." || e.key === "+") {
      e.preventDefault();
    }
  };

  const handleClick = () => {
    if (city !== "" && description !== "" && price !== 0) {
      dispatch(saveOfferCityAndDescription({ city, price, description }));
      navigate("/makeoffer/step3");
    } else {
      setMessage("Veuillez entrer tous les informations nécessaires");
    }
  };
  useEffect(() => {
    document.title = "Création d'annonce | L'matarian";
  }, []);
  return (
    <div className="secondstepscreen">
      <div className="secondstepscreen__inside">
        <h1>Créer votre annonce. C'est Facile!</h1>
        <OfferSteps step1 step2 />
        <div className="step2__box">
          <h2>Plus spécifique!</h2>

          <div className="offer__name__step2">
            <label>Ville</label>
            <AllCities city={city} setCity={setCity} />
          </div>

          <div className="offer__name__step2">
            <label>Prix</label>
            <input
              type="number"
              value={price}
              placeholder={type === "rent" ? "prix par jour" : "prix"}
              onChange={(e) => setPrice(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>

          <div className="offer__name__step2">
            <label>Déscription</label>
            <textarea
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons__section">
          <Link to={"/makeoffer/step1"} className="continue__btn">
            Retour
          </Link>
          <button className="continue__btn" onClick={handleClick}>
            Suivant
          </button>
        </div>
        {message && <p className="offer__error">{message}</p>}
      </div>
    </div>
  );
};

export default SecondStepScreen;
