import React from "react";

const AllCategories = ({ category, setCategory }) => {
  return (
    <select
      value={category}
      className="allcities"
      onChange={(e) => setCategory(e.target.value)}
    >
      <option value={""}>Choisissez une catégorie</option>
      <option value={"Travaux en hauteur"}>Travaux en hauteur</option>
      <option value={"Levage et manutention"}>Levage et manutention</option>
      <option value={"Terrassement et excavation"}>
        Terrassement et excavation
      </option>
      <option value={"Transport et logistique"}>Transport et logistique</option>
      <option value={"Gros oeuvre et route"}>Gros oeuvre et route</option>
      <option value={"énergie et pompage"}>énergie et pompage</option>
      <option value={"Equipement de chantier"}>Equipement de chantier</option>
      <option value={"Base vie et installation de chantier"}>
        Base vie et installation de chantier
      </option>
      <option value={"Autres"}>Autres</option>
    </select>
  );
};

export default AllCategories;
