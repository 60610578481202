import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../redux/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import "./NavBar.css";

const NavBar = ({ click }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };
  const { userInfo } = userLogin;
  return (
    <>
      <nav className="navbar">
        <div className="nav__logo">
          <Link to="/">l'matarian</Link>
        </div>
        <ul className="navbar__links">
          <li>
            <Link to="/discover/page/1">Découvrir</Link>
          </li>

          <li className="vertical__">|</li>
          {userInfo && !userInfo.isAdmin ? (
            <>
              <li>
                <Link to="/profile">
                  <p>Profile</p>
                </Link>
              </li>
              <li className="vertical__">|</li>
              <li className="annonce__link">
                <Link to="/newoffer">Nouvelle annonce ?</Link>
              </li>
              <li className="vertical__">|</li>
              <li onClick={logoutHandler} className="signout__link">
                Déconnexion
              </li>
            </>
          ) : userInfo && userInfo.isAdmin ? (
            <>
              <li>
                <Link to="/profile">
                  <p>Profile</p>
                </Link>
              </li>
              <li className="vertical__">|</li>
              <li className="annonce__link">
                <Link to="/newoffer">Nouvelle annonce ?</Link>
              </li>
              <li className="vertical__">|</li>
              <li>
                <Link to="/dash/global">
                  <b>Dashboard</b>
                </Link>
              </li>
              <li className="vertical__">|</li>
              <li onClick={logoutHandler} className="signout__link">
                Déconnexion
              </li>
            </>
          ) : (
            <>
              <li className="annonce__link">
                <Link to="/makeoffer/step1">Vous avez une annonce ?</Link>
              </li>
              <li className="vertical__">|</li>
              <li>
                <Link to="/login">Connéxion</Link>
              </li>
            </>
          )}
        </ul>
        <div className="threeLines" onClick={click}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
