import React from "react";

const TravauxEnHateur = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>
      <option value={"Camion nacelle"}>Camion nacelle</option>
      <option value={"Nacelle araignée"}>Nacelle araignée</option>
      <option value={"Nacelle télescopique"}>Nacelle télescopique</option>
      <option value={"Nacelle articulée"}>Nacelle articulée</option>
      <option value={"Nacelle ciseaux"}>Nacelle ciseaux</option>
      <option value={"Nacelle toucan"}>Nacelle toucan</option>
      <option value={"Nacelle élévatrice"}>Nacelle élévatrice</option>
    </select>
  );
};

export default TravauxEnHateur;
