import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomeScreen.css";
import RecommendedItem from "../components/RecommendedItem";
import { listBuyOffers, listRentOffers } from "../redux/actions/offerActions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer";
import back1 from "../assets/back1.svg";
import back2 from "../assets/back2.svg";
import back3 from "../assets/back3.svg";
import back5 from "../assets/back5.svg";
import back6 from "../assets/back6.svg";
import back4 from "../assets/back4.svg";

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const offersBuy = useSelector((state) => state.offersBuy);
  const { buyOffers, error: buyError, loading: buyLoading } = offersBuy;

  const offersRent = useSelector((state) => state.offersRent);
  const { rentOffers, error: rentError, loading: rentLoading } = offersRent;

  useEffect(() => {
    document.title = "Accueil | l'matarian";
    dispatch(listBuyOffers());
    dispatch(listRentOffers());
  }, [dispatch]);

  const dropboxVideoLink =
    "https://www.dropbox.com/scl/fi/70dg1akgpoqfym91jdia5/Pr-sentation3.mp4?rlkey=6olznxpyogbz6vtu4zivhonlq&dl=0";

  const directDownloadLink = dropboxVideoLink.replace(
    "www.dropbox.com",
    "dl.dropboxusercontent.com"
  );

  return (
    <div className="homescreen_container">
      <div className="homescreen">
        <h1>Trouver l'annonce qui vous convient</h1>
        <div className="homescreen__before__inside">
          <div className="homescreen__inside">
            <p>Nouvelles annonces de la vente</p>
            <div className="recommended__list">
              {buyLoading ? (
                <div className="home__loading">
                  <div className="spinner"></div>
                </div>
              ) : buyError ? (
                <h2>{buyError}</h2>
              ) : (
                buyOffers
                  .slice(0, 4)
                  .map((item) => (
                    <RecommendedItem
                      key={item._id}
                      id={item._id}
                      title={item.title}
                      images={item.images}
                      subCateg={item.subcategory}
                    />
                  ))
              )}
            </div>
          </div>
          <div className="homescreen__inside">
            <p>Nouvelles annonces de location</p>
            <div className="recommended__list">
              {rentLoading ? (
                <div className="home__loading">
                  <div className="spinner"></div>
                </div>
              ) : rentError ? (
                <h2>{rentError}</h2>
              ) : (
                rentOffers
                  .slice(0, 4)
                  .map((item) => (
                    <RecommendedItem
                      key={item._id}
                      id={item._id}
                      title={item.title}
                      images={item.images}
                      subCateg={item.subcategory}
                    />
                  ))
              )}
            </div>
          </div>
        </div>
        <div className="categ_section">
          <h1>Naviguez vers vos catégories préférés</h1>
          <div className="all__categs">
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Travaux en hauteur" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <i className="fa-solid fa-water-ladder"></i>
              </div>
              <p>Travaux en hauteur</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Levage et manutention" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img src={back4} className="categ__pic" alt="icon_levage" />
              </div>
              <p>Levage et manutention</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Terrassement et excavation" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <i className="fa-solid fa-person-digging"></i>
              </div>
              <p>Terrassement et excavation</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Transport et logistique" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img src={back5} className="categ__pic" alt="transport" />
              </div>
              <p>Transport et logistique</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Gros oeuvre et route" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img
                  src={back1}
                  className="categ__pic"
                  alt="icon_gros_oeuvre"
                />
              </div>
              <p>Gros oeuvre et route</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "énergie et pompage" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img src={back2} className="categ__pic" alt="icon_energie" />
              </div>
              <p>Energie et pompage</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Equipement de chantier" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img src={back6} className="categ__pic" alt="icon_equipement" />
              </div>
              <p>Equipement de chantier</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1", {
                  state: { keyword: "Base vie et installation de chantier" },
                });
              }}
              className="categ__inner__section"
            >
              <div>
                <img src={back3} className="categ__pic" alt="icon_base_vie" />
              </div>
              <p>Base vie et installation de chantier</p>
            </button>
            <button
              onClick={() => {
                navigate("/discover/page/1");
              }}
              className="categ__inner__section"
            >
              <div>
                <i className="fa-solid fa-plus fa-fade"></i>
              </div>
              <p>Autres catégories</p>
            </button>
          </div>
        </div>
        <div className="video_section">
          <h2>Découvrez les avantages que notre platforme vous offre !</h2>
          <span>
            <video controls className="intro__video" controlsList="nodownload">
              <source src={directDownloadLink} type="video/mp4" />
            </video>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
