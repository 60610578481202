import React from "react";
import "./OfferSteps.css";

const OfferSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <div className="row checkout-steps">
      <div className={step1 ? "active__circle" : "inactive__circle"}>1</div>
      <div className={step2 ? "active__bar" : "inactive__bar"}></div>
      <div className={step2 ? "active__circle" : "inactive__circle"}>2</div>
      <div className={step3 ? "active__bar" : "inactive__bar"}></div>
      <div className={step3 ? "active__circle" : "inactive__circle"}>3</div>
      <div className={step4 ? "active__bar" : "inactive__bar"}></div>
      <div className={step4 ? "active__circle" : "inactive__circle"}>4</div>
    </div>
  );
};

export default OfferSteps;
