import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FirstStepScreen.css";

//Categories
import AllCategories from "../components/AllCategories";
import OfferSteps from "../components/OfferSteps";
import BaseVie from "../components/subcatgories/BaseVie";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import "./FirstStepScreen.css";
import { useDispatch } from "react-redux";
import { saveOfferTitleAndCategory } from "../redux/actions/offerActions";

const FirstStepScreen = () => {
  const titleAndCategoryStorage = localStorage.getItem("titleAndCategory");
  const titleAndCategoryParsed = JSON.parse(titleAndCategoryStorage);

  const [offerName, setOfferName] = useState(
    titleAndCategoryParsed ? titleAndCategoryParsed.offerName : ""
  );
  const [category, setCategory] = useState(
    titleAndCategoryParsed ? titleAndCategoryParsed.category : ""
  );
  const [subCategory, setSubCategory] = useState(
    titleAndCategoryParsed ? titleAndCategoryParsed.subCategory : ""
  );
  const [type, setType] = useState(
    titleAndCategoryParsed ? titleAndCategoryParsed.type : ""
  );
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (
      offerName !== "" &&
      type !== "" &&
      category !== "" &&
      subCategory !== ""
    ) {
      dispatch(
        saveOfferTitleAndCategory({ offerName, type, category, subCategory })
      );
      navigate("/makeoffer/step2");
    } else {
      setMessage("Veuillez entrer tous les informations nécessaires");
    }
  };

  useEffect(() => {
    document.title = "Création d'annonce";
  }, []);

  return (
    <div className="firststepscreen">
      <div className="firststepscreen__inside">
        <h1>Créer votre annonce. C'est Facile!</h1>
        <OfferSteps step1 />

        <div className="step1__box">
          <h2>Informations Générales</h2>
          <div className="offer__name__step1">
            <label>Titre de l'offre</label>
            <input
              className=""
              type="text"
              value={offerName}
              onChange={(e) => setOfferName(e.target.value)}
            />
          </div>
          <div className="newoffer__name">
            <label>Type de d'annonce</label>
            <select
              value={type}
              className=""
              onChange={(e) => setType(e.target.value)}
            >
              <option value={""}>Choisissez le type d'annonce</option>
              <option value={"rent"}>Location</option>
              <option value={"buy"}>Vente</option>
            </select>
          </div>
          <div className="offer__name__step1">
            <label>Catégorie</label>
            <AllCategories category={category} setCategory={setCategory} />
          </div>

          <div className="offer__name__step1">
            {category === "Travaux en hauteur" ? (
              <>
                <label>Sous-catégorie</label>
                <TravauxEnHateur
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Levage et manutention" ? (
              <>
                <label>Sous-catégorie</label>
                <LevageEtManutention
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Terrassement et excavation" ? (
              <>
                <label>Sous-catégorie</label>
                <TerrassementEtExcavation
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Transport et logistique" ? (
              <>
                <label>Sous-catégorie</label>
                <TransportEtLogistique
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Gros oeuvre et route" ? (
              <>
                <label>Sous-catégorie</label>
                <GrosOeuvre
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "énergie et pompage" ? (
              <>
                <label>Sous-catégorie</label>
                <EnergieEtPompage
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Equipement de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <EquipementDeChantier
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Base vie et installation de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <BaseVie
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <button className="continue__btn" onClick={handleClick}>
          Suivant
        </button>
        {message && <p className="offer__error">{message}</p>}
      </div>
    </div>
  );
};

export default FirstStepScreen;
