import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MakeOfferScreen.css";
import { motion } from "framer-motion";
import pictures from "../assets/image2.png";

//Sub-Categories
import AllCities from "../components/AllCities";
import AllCategories from "../components/AllCategories";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import BaseVie from "../components/subcatgories/BaseVie";
import { createOffer } from "../redux/actions/offerActions";
import { OFFER_CREATE_RESET } from "../redux/constants/offerConstants";
import axios from "axios";
import Footer from "../components/Footer";

const MakeOfferScreen = () => {
  // offer info
  const [image, setImage] = useState("");
  const [offerName, setOfferName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [city, setCity] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");

  // offer uploading condition variables
  const [uploading, setUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [stagedFormData, setStagedFormData] = useState(null);
  //
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [conditionError, setConditionError] = useState("");
  const [sentImages, setSentImages] = useState(null);

  // Todo: New implementations
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageCountError, setImageCountError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offerCreate = useSelector((state) => state.offerCreate);
  const { success, error, loading } = offerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleClick = async (e) => {
    e.preventDefault();
    if (imagePreviews.length === 0) {
      setImageCountError("Vous devez ajouter une image.");
      return;
    } else {
      setUploading(true);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          "/api/upload/",
          stagedFormData,
          config
        );

        if (data?.length > 0) {
          dispatch(
            createOffer({
              user: userInfo._id,
              title: offerName,
              city,
              category,
              subcategory: subCategory,
              images: data.map((image) => `${image}`),
              type,
              price,
              description: desc,
            })
          );
          setUploading(false);
        }
      } catch (error) {
        console.error(error);
        setUploading(false);
      }
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newPreviews = [];

    const formdata = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const reader = new FileReader();

      reader.onload = (event) => {
        newPreviews.push(event.target.result);
        if (newPreviews.length === files.length) {
          setImagePreviews([...imagePreviews, ...newPreviews]);
        }
      };

      formdata.append("images", files[i]);

      reader.readAsDataURL(file);
    }
    setStagedFormData(formdata);
  };

  const deleteImagePreview = (index) => {
    if (index < 0 || index >= imagePreviews.length) {
      return;
    }

    const imageIndex = imagePreviews.findIndex((_, i) => i === index);

    if (imageIndex === -1) {
      return;
    }
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

    setImagePreviews(updatedPreviews);
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "-" ||
      e.key === "e" ||
      e.key === "." ||
      e.key === "+" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown"
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (userInfo) {
      document.title = "Création d'annonce";

      if (success && !uploading) {
        navigate("/offersuccess");
        dispatch({
          type: OFFER_CREATE_RESET,
        });
      }
    } else {
      navigate("/");
    }
  }, [userInfo, navigate, success, uploading, dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.3 }}
      className="makenewoffer__wrapper"
    >
      <div className="makenewoffer">
        <h1>Ajouter une annonce</h1>
        <div className="image__upload">
          <div className="inside__upload">
            <label htmlFor="upload-input">
              <img src={pictures} draggable="false" alt="pictures" />
              {/* <p>Ajouter des photos</p> */}
            </label>
            <input
              id="upload-input"
              type="file"
              onChange={handleImageChange}
              multiple
              accept="image/*"
            />
          </div>
          <div className="image_preview_container">
            {imagePreviews.length > 0 ? (
              imagePreviews.map((preview, index) => (
                <div className="image-preview" key={index}>
                  <img
                    src={preview}
                    alt={`Image ${15}`}
                    className="image__preview"
                  />
                  <i
                    className="fa-solid fa-trash close-icon"
                    onClick={() => deleteImagePreview(index)}
                  ></i>
                </div>
              ))
            ) : (
              <div className="conseil">
                <div className="conseil__inside">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  <h4>Conseils</h4>
                </div>

                <ul>
                  <li>
                    Une annonce avec des images indicatifs est plus visible aux
                    utilisateurs
                  </li>
                  <li>Remplir tous les cases nécessaires</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="newoffer__details">
          <div className="newoffer__name">
            <label>Titre d'annonce</label>
            <input
              className=""
              type="text"
              value={offerName}
              onChange={(e) => setOfferName(e.target.value)}
            />
          </div>
          <div className="newoffer__name">
            <label>Type de d'annonce</label>
            <select
              value={type}
              className=""
              onChange={(e) => setType(e.target.value)}
            >
              <option value={""}>Choisissez le type d'annonce</option>
              <option value={"rent"}>Location</option>
              <option value={"buy"}>Vente</option>
            </select>
          </div>
          <div className="newoffer__name">
            <label>Catégorie</label>
            <AllCategories category={category} setCategory={setCategory} />
          </div>

          <div className="newoffer__name">
            {category === "Travaux en hauteur" ? (
              <>
                <label>Sous-catégorie</label>
                <TravauxEnHateur
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Levage et manutention" ? (
              <>
                <label>Sous-catégorie</label>
                <LevageEtManutention
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Terrassement et excavation" ? (
              <>
                <label>Sous-catégorie</label>
                <TerrassementEtExcavation
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Transport et logistique" ? (
              <>
                <label>Sous-catégorie</label>
                <TransportEtLogistique
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Gros oeuvre et route" ? (
              <>
                <label>Sous-catégorie</label>
                <GrosOeuvre
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "énergie et pompage" ? (
              <>
                <label>Sous-catégorie</label>
                <EnergieEtPompage
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Equipement de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <EquipementDeChantier
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : category === "Base vie et installation de chantier" ? (
              <>
                <label>Sous-catégorie</label>
                <BaseVie
                  subCategory={subCategory}
                  setSubCategory={setSubCategory}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="newoffer__name">
            <label>Ville</label>
            <AllCities city={city} setCity={setCity} />
          </div>

          <div className="newoffer__name">
            <label>Prix</label>
            <input
              type="number"
              value={price}
              className="no_spinners"
              placeholder={type === "rent" ? "prix par jour" : "prix"}
              onChange={(e) => setPrice(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>

          <div className="offer__name__step2">
            <label>Déscription</label>
            <textarea
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
        </div>
        {error && <h4 className="offer__error">{error}</h4>}
        {imageCountError && <h4 className="offer__error">{imageCountError}</h4>}
        <button
          type="button"
          onClick={handleClick}
          className="createoffer__btn"
        >
          Créer cette annonce
        </button>
      </div>
      <Footer />
    </motion.div>
  );
};

export default MakeOfferScreen;
