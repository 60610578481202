import React from "react"

const TransportEtLogistique = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>
      <option
        value={"camion benne (camion  8*4 camion 6*4 camion 6*2 camion 4*2)"}
      >
        camion benne (camion 8*4 camion 6*4 camion 6*2 camion 4*2)
      </option>
      <option value={"camion remorque"}>camion remorque</option>
      <option value={"camion fourgon"}>camion fourgon</option>
      <option value={"camion-citerne"}>camion-citerne</option>
      <option value={"camion ampli roll (à bras )"}>
        camion ampli roll (à bras )
      </option>
      <option value={"camion plateau"}>camion plateau</option>
      <option value={"camion porte char"}>camion porte char</option>
      <option value={"pickup"}>pickup</option>
      <option value={"voiture"}>voiture</option>
      <option value={"tracteur à chariot"}>tracteur à chariot</option>
    </select>
  )
}

export default TransportEtLogistique
