import React from "react";

const TerrassementEtExcavation = ({ subCategory, setSubCategory }) => {
  return (
    <select
      value={subCategory}
      className="allcities"
      onChange={(e) => setSubCategory(e.target.value)}
    >
      <option value={""}>Choisissez une sous-catégorie</option>
      <option value={"bulldozer"}>bulldozer</option>
      <option value={"décapeuse"}>décapeuse</option>
      <option value={"chargeuse"}>chargeuse</option>
      <option value={"mini-chargeuse"}>mini-chargeuse</option>
      <option value={"pelle hydraulique"}>pelle hydraulique</option>
      <option value={"mini-pelle hydraulique"}>mini-pelle hydraulique</option>
      <option value={"tractopelle"}>tractopelle</option>
      <option value={"dumper"}>dumper</option>
      <option value={"niveleuse"}>niveleuse</option>
      <option value={"compacteur"}>compacteur</option>
      <option value={"vibrant à cylindre lisse"}>
        vibrant à cylindre lisse
      </option>
      <option value={"compacteur à pneu"}>compacteur à pneu</option>
      <option value={"compacteur vibrant à pied dameur"}>
        compacteur vibrant à pied dameur
      </option>
      <option value={"dame sauteuse"}>dame sauteuse</option>
    </select>
  );
};

export default TerrassementEtExcavation;
