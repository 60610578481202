import React from "react";
import { Link } from "react-router-dom";
import "./Paginate.css";

const Paginate = ({ pages, page }) => {
  return (
    <div className="paginate">
      {[...Array(pages).keys()].map((x) => (
        <Link to={`/discover/page/${x + 1}`} key={x + 1}>
          <p style={{ color: x + 1 === page ? "#ea8c55" : "white" }}>{x + 1}</p>
        </Link>
      ))}
    </div>
  );
};

export default Paginate;
