import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//reducers

import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  offerApproveReducer,
  offerBuyListReducer,
  offerCreateReducer,
  offerDeleteReducer,
  offerDetailsReducer,
  offerLevageListReducer,
  offerListMyReducer,
  offerListReducer,
  offerRentListReducer,
  offerTerrassmentListReducer,
  offerTransportListReducer,
  offerTravauxListReducer,
  offerUnapprovedReducer,
  offerUnnaprovedDeleteReducer,
  offerUpdateReducer,
  offersTotalReducer,
} from "./reducers/offerReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  offerCreate: offerCreateReducer,
  offersList: offerListReducer,
  offersUnappList: offerUnapprovedReducer,
  offersTotal: offersTotalReducer,
  offersBuy: offerBuyListReducer,
  offersRent: offerRentListReducer,
  travauxState: offerTravauxListReducer,
  levageState: offerLevageListReducer,
  terrassementState: offerTerrassmentListReducer,
  transportState: offerTransportListReducer,
  offerDetails: offerDetailsReducer,
  offerUpdate: offerUpdateReducer,
  offerDelete: offerDeleteReducer,
  myOffersList: offerListMyReducer,
  offerApprove: offerApproveReducer,
  offerUnapprovedDelete: offerUnnaprovedDeleteReducer,
});

const middlware = [thunk];

const UserInfoInLocaleStorage = localStorage.getItem("UserInfos")
  ? JSON.parse(localStorage.getItem("UserInfos"))
  : null;

const titleAndCategoryFromStorage = localStorage.getItem("titleAndCategory")
  ? JSON.parse(localStorage.getItem("titleAndCategory"))
  : {};
const cityAndDescFromStorage = localStorage.getItem("cityAndDescription")
  ? JSON.parse(localStorage.getItem("cityAndDescription"))
  : {};
const initialState = {
  userLogin: {
    userInfo: UserInfoInLocaleStorage,
  },
  offerCreate: {
    titleAndCategory: titleAndCategoryFromStorage,
    cityAndDescription: cityAndDescFromStorage,
  },
};

let store;

if (process.env.NODE_ENV === "development") {
  store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlware))
  );
} else {
  store = createStore(reducer, initialState, applyMiddleware(...middlware));
}

export default store;
