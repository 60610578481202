import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "./AdminDashScreen.css";
import { listUsers } from "../redux/actions/userActions";
import {
  offersTotal,
  listUnapprovedOffers,
  approveOffer,
  deleteUnapprovedOffer,
} from "../redux/actions/offerActions";
import Footer from "../components/Footer";

const AdminDashScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.userList);
  const { total } = useSelector((state) => state.offersTotal);
  const { unOffers } = useSelector((state) => state.offersUnappList);
  //
  const { offers, total: unTotal } = unOffers;
  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const offerApprove = useSelector((state) => state.offerApprove);
  const { updateOfferLoading, updateOfferSuccess, offerUpdateError } =
    offerApprove;
  const [approved, setApproved] = useState(false);

  const handleOfferApproving = (e) => {
    dispatch(approveOffer(e._id, approved));
    setApproved((prevApproved) => !prevApproved);
  };

  const handleOfferDeleting = (e) => {
    const userConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette offre ?"
    );
    if (userConfirmed) {
      dispatch(deleteUnapprovedOffer(e));
    }
  };

  useEffect(() => {
    document.title = "Admin dashboard | L'matarian";
    if (userInfo) {
      dispatch(listUsers());
      dispatch(offersTotal());
      dispatch(listUnapprovedOffers());
    } else {
      navigate("/");
    }
  }, [dispatch, userInfo, navigate]);
  return (
    <div className="admindashscreen">
      <div className="dashscreen_inside">
        <div className="dashscreen_users">
          <h1>Tous les utilisateurs</h1>
          <table>
            <thead>
              <tr>
                <th>username</th>
                <th>Nom complet</th>
                <th>email</th>
                <th>téléphone</th>
              </tr>
              {users &&
                users.map((e) => (
                  <tr key={e._id}>
                    <td>{e.userName}</td>
                    <td>{e.fullName}</td>
                    <td>{e.email}</td>
                    <td>0{e.phoneNumber}</td>
                  </tr>
                ))}
            </thead>
          </table>
        </div>
        <div className="users_total_section">
          {users && (
            <div className="users_total">
              <h3>Total des utilisateurs</h3>
              <div className="users_totalinside">
                <p>{users.length}</p>
              </div>
            </div>
          )}
          {total && (
            <div className="users_total">
              <h3>Total des Offres</h3>
              <div className="users_totalinside">
                <p>{total}</p>
              </div>
            </div>
          )}
          {unOffers && unTotal && (
            <div className="users_total">
              <h3>Total des Offres non approuvées</h3>
              <div className="users_totalinside">
                <p>{unTotal}</p>
              </div>
            </div>
          )}
        </div>

        <div className="dashscreen_offers">
          <h1>Tous les offres Non Approuvées</h1>
          <table>
            <thead>
              <tr>
                <th>title</th>
                <th>catégorie</th>
                <th>sub-catégorie</th>
                <th>consulter</th>
                <th>Approuver</th>
                <th>supprimer</th>
              </tr>
              {unOffers &&
                offers &&
                offers.length > 0 &&
                offers.map((e) => (
                  <tr key={e._id}>
                    <td>{e.title}</td>
                    <td>{e.category}</td>
                    <td>{e.subcategory}</td>
                    <td>
                      <Link to={`/offer/${e._id}`}>
                        <i className="fa-solid fa-eye"></i>
                      </Link>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        value={approved}
                        onClick={() => handleOfferApproving(e)}
                      />
                    </td>
                    <td onClick={() => handleOfferDeleting(e._id)}>
                      <i className="fa-solid fa-trash trash_icon"></i>
                    </td>
                  </tr>
                ))}
            </thead>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashScreen;
