import {
  OFFER_CREATE_REQUEST,
  OFFER_CREATE_SUCCESS,
  OFFER_CREATE_FAIL,
  OFFER_DETAILS_REQUEST,
  OFFER_DETAILS_SUCCESS,
  OFFER_DETAILS_FAIL,
  OFFER_LIST_MY_REQUEST,
  OFFER_LIST_MY_SUCCESS,
  OFFER_LIST_MY_FAIL,
  OFFER_LIST_MY_RESET,
  OFFER_LIST_FAIL,
  OFFER_LIST_SUCCESS,
  OFFER_LIST_REQUEST,
  OFFER_TOTAL_FAIL,
  OFFER_TOTAL_SUCCESS,
  OFFER_TOTAL_REQUEST,
  OFFER_CREATE_RESET,
  OFFER_UPDATE_MY_REQUEST,
  OFFER_UPDATE_MY_SUCCESS,
  OFFER_UPDATE_MY_FAIL,
  OFFER_UPDATE_MY_RESET,
  OFFER_DELETE_MY_REQUEST,
  OFFER_DELETE_MY_SUCCESS,
  OFFER_DELETE_MY_FAIL,
  OFFER_SAVE_TITLE_AND_CATEGORY,
  OFFER_SAVE_CITY_AND_DESCRIPTION,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS,
  OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL,
  OFFER_LIST_LEVAGE_REQUEST,
  OFFER_LIST_LEVAGE_SUCCESS,
  OFFER_LIST_LEVAGE_FAIL,
  OFFER_LIST_TERRASSEMENT_REQUEST,
  OFFER_LIST_TERRASSEMENT_SUCCESS,
  OFFER_LIST_TERRASSEMENT_FAIL,
  OFFER_LIST_TRANSPORT_REQUEST,
  OFFER_LIST_TRANSPORT_SUCCESS,
  OFFER_LIST_TRANSPORT_FAIL,
  OFFER_LIST_UNAPPROVED_REQUEST,
  OFFER_LIST_UNAPPROVED_SUCCESS,
  OFFER_LIST_UNAPPROVED_FAIL,
  OFFER_LIST_RENT_REQUEST,
  OFFER_LIST_RENT_SUCCESS,
  OFFER_LIST_RENT_FAIL,
  OFFER_LIST_BUY_REQUEST,
  OFFER_LIST_BUY_SUCCESS,
  OFFER_LIST_BUY_FAIL,
  OFFER_APPROVE_REQUEST,
  OFFER_APPROVE_SUCCESS,
  OFFER_APPROVE_FAIL,
  OFFER_ADMIN_DELETE_REQUEST,
  OFFER_ADMIN_DELETE_SUCCESS,
  OFFER_ADMIN_DELETE_FAIL,
} from "../constants/offerConstants";

export const offerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case OFFER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        offer: action.payload,
      };
    case OFFER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFER_SAVE_TITLE_AND_CATEGORY:
      return {
        ...state,
        titleAndCategory: action.payload,
      };
    case OFFER_SAVE_CITY_AND_DESCRIPTION:
      return {
        ...state,
        cityAndDescription: action.payload,
      };
    case OFFER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const offerUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_UPDATE_MY_REQUEST:
      return {
        updateOfferLoading: true,
      };
    case OFFER_UPDATE_MY_SUCCESS:
      return {
        updateOfferLoading: false,
        updateOfferSuccess: true,
      };
    case OFFER_UPDATE_MY_FAIL:
      return {
        updateOfferLoading: false,
        offerUpdateError: action.payload,
      };
    case OFFER_UPDATE_MY_RESET:
      return {};
    default:
      return state;
  }
};

export const offerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_DELETE_MY_REQUEST:
      return { loading: true };
    case OFFER_DELETE_MY_SUCCESS:
      return { loading: false, success: true };
    case OFFER_DELETE_MY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const offerDetailsReducer = (
  state = { loading: true, offer: {} },
  action
) => {
  switch (action.type) {
    case OFFER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OFFER_DETAILS_SUCCESS:
      return {
        loading: false,
        offer: action.payload,
      };
    case OFFER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerListMyReducer = (state = { myOffers: [] }, action) => {
  switch (action.type) {
    case OFFER_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case OFFER_LIST_MY_SUCCESS:
      return {
        loading: false,
        myOffers: action.payload,
      };
    case OFFER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case OFFER_LIST_MY_RESET:
      return { myOffers: [] };
    default:
      return state;
  }
};

export const offerListReducer = (state = { offers: [] }, action) => {
  switch (action.type) {
    case OFFER_LIST_REQUEST:
      return {
        loading: true,
        offers: [],
      };
    case OFFER_LIST_SUCCESS:
      return {
        loading: false,
        offers: action.payload.offers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case OFFER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerBuyListReducer = (state = { buyOffers: [] }, action) => {
  switch (action.type) {
    case OFFER_LIST_BUY_REQUEST:
      return {
        loading: true,
        buyOffers: [],
      };
    case OFFER_LIST_BUY_SUCCESS:
      return {
        loading: false,
        buyOffers: action.payload.offers,
      };
    case OFFER_LIST_BUY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerRentListReducer = (state = { rentOffers: [] }, action) => {
  switch (action.type) {
    case OFFER_LIST_RENT_REQUEST:
      return {
        loading: true,
        rentOffers: [],
      };
    case OFFER_LIST_RENT_SUCCESS:
      return {
        loading: false,
        rentOffers: action.payload.offers,
      };
    case OFFER_LIST_RENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerUnapprovedReducer = (state = { unOffers: {} }, action) => {
  switch (action.type) {
    case OFFER_LIST_UNAPPROVED_REQUEST:
      return {
        loading: true,
        unOffers: {},
      };
    case OFFER_LIST_UNAPPROVED_SUCCESS:
      return {
        loading: false,
        unOffers: action.payload,
      };
    case OFFER_LIST_UNAPPROVED_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offersTotalReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_TOTAL_REQUEST:
      return {
        loading: true,
      };
    case OFFER_TOTAL_SUCCESS:
      return {
        loading: false,
        total: action.payload,
      };
    case OFFER_TOTAL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerTravauxListReducer = (
  state = { travauxOffers: [] },
  action
) => {
  switch (action.type) {
    case OFFER_LIST_TRAVAUX_EN_HAUTEUR_REQUEST:
      return {
        loading: true,
        travauxOffers: [],
      };
    case OFFER_LIST_TRAVAUX_EN_HAUTEUR_SUCCESS:
      return {
        loading: false,
        travauxOffers: action.payload.offers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case OFFER_LIST_TRAVAUX_EN_HAUTEUR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerLevageListReducer = (
  state = { levageOffers: [] },
  action
) => {
  switch (action.type) {
    case OFFER_LIST_LEVAGE_REQUEST:
      return {
        loading: true,
        levageOffers: [],
      };
    case OFFER_LIST_LEVAGE_SUCCESS:
      return {
        loading: false,
        levageOffers: action.payload.offers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case OFFER_LIST_LEVAGE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerTerrassmentListReducer = (
  state = { terrassementOffers: [] },
  action
) => {
  switch (action.type) {
    case OFFER_LIST_TERRASSEMENT_REQUEST:
      return {
        loading: true,
        terrassementOffers: [],
      };
    case OFFER_LIST_TERRASSEMENT_SUCCESS:
      return {
        loading: false,
        terrassementOffers: action.payload.offers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case OFFER_LIST_TERRASSEMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerTransportListReducer = (
  state = { transportOffers: [] },
  action
) => {
  switch (action.type) {
    case OFFER_LIST_TRANSPORT_REQUEST:
      return {
        loading: true,
        transportOffers: [],
      };
    case OFFER_LIST_TRANSPORT_SUCCESS:
      return {
        loading: false,
        transportOffers: action.payload.offers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case OFFER_LIST_TRANSPORT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const offerApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_APPROVE_REQUEST:
      return {
        offerApproveLoading: true,
      };
    case OFFER_APPROVE_SUCCESS:
      return {
        offerApproveLoading: false,
        offerApproveSuccess: true,
      };
    case OFFER_APPROVE_FAIL:
      return {
        offerApproveLoading: false,
        offerUpdateError: action.payload,
      };
    default:
      return state;
  }
};

export const offerUnnaprovedDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_ADMIN_DELETE_REQUEST:
      return { loading: true };
    case OFFER_ADMIN_DELETE_SUCCESS:
      return { loading: false, success: true };
    case OFFER_ADMIN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
