import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./RecommendedItem.css";
import default_pic from "../assets/default_pic.png";

const RecommendedItem = ({ id, title, subCateg, images }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="recommendeditem">
      <Link to={`/offer/${id}`}>
        {imageError || !images.length === 0 ? (
          <img src={default_pic} alt="Fallback Icon" />
        ) : (
          <img
            src={images[0]}
            alt={`image_de_la_machine${id}`}
            onError={handleImageError}
          />
        )}
      </Link>
      <h3>{title}</h3>
      <p>{subCateg}</p>
    </div>
  );
};

export default RecommendedItem;
