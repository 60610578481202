import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./LoginScreen.css";
import { loginUser } from "../redux/actions/userActions";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;
  useEffect(() => {
    document.title = "Se Connecter | l'matarian";
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, navigate, redirect]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  return (
    <div className="loginscreen">
      <div className="loginscreen__inside">
        <h1 className="login__header">Connéxion</h1>
        {error && <div className="signIn__error">{error}</div>}
        {loading && <div className="spinner2"></div>}
        <form onSubmit={handleSubmit} className="form__elements">
          <div className="form__insides">
            <div className="username__section">
              <label>Email</label>
              <input
                type="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="username__section">
              <label>Mot de passe</label>
              <input
                type="password"
                placeholder="Enter votre mot de passe"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="login__links_wrapper">
            <p className="login__link">
              Vous n'avez pas encore de compte ? {"  "}
              <Link to="/register">Créez-en un !</Link>
            </p>
            <p className="login__link">
              Mot de pass oublié ?{"  "}
              <Link to="/passinstruct">Réinitialiser le mot de passe !</Link>
            </p>
          </div>
          <button type="submit" className="login__btn">
            Se connecter
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
